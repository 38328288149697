<template>
  <h2>Zarządzaj kodami</h2>
  <form @submit.prevent="saveCodes()">
    <div class="form-group row text-start">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <template v-if="selectedPackageType === 'funpackage' || selectedPackageType === 'deal'">
          <div class="form-check">
            <div class="col-sm-5">
              <input class="form-check-input" type="radio" name="codes" id="insertCodes"
                     v-on:click="setSelectedCodesOption('insertCodes')">
            </div>
            <label class="form-check-label" for="insertCodes">
              Podaj kody (odzielone przecinkiem)
            </label>
            <template v-if="selectedCodesOption === 'insertCodes'">
              <div class="form-group">
                <label for="codes"></label>
                <textarea class="form-control" id="codes" rows="3" v-model="codesData.codes"></textarea>
              </div>
            </template>
          </div>

          <div class="form-check">
            <input class="form-check-input" type="radio" name="codes" id="insertCodesNumber"
                   v-on:click="setSelectedCodesOption('insertCodesNumber')">
            <label class="form-check-label" for="insertCodesNumber">
              Podaj liczbę kodów
            </label>
            <template v-if="selectedCodesOption === 'insertCodesNumber'">
              <div class="form-group row">
                <div class="col-sm-10">
                  <input class="form-control" id="numberOfCodesToGenerate" v-model="codesData.numberOfCodesToGenerate">
                </div>
              </div>
            </template>
          </div>

        </template>
        <template v-if="selectedPackageType === 'access_code' || selectedPackageType === 'discount_code'">
          <!--            TODO: Refactor. This code is used to reset setSelectedCodesOption to prevent show codes or codesNumber field when radio is not selected-->
          {{setSelectedCodesOption(null)}}
          <div class="form-group row">
            <label for="codes" class="col-sm-2 col-form-label">Podaj kod:</label>
            <div class="col-sm-10">
              <input class="form-control" id="codes" v-model="codesData.codes">
            </div>
          </div>
        </template>
      </div>
      <div class="col-md-3"></div>
    </div>
    <button type="submit" class="btn btn-primary">Zapisz</button>
  </form>
<!--JOB END Skonczylem na robieniu listu kodow w danym pakiecie-->
  <template v-if="selectedPackageType === 'funpackage' || selectedPackageType === 'deal'">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <table id="codes-table">
          <tr>
            <th style="width: 2%;">ID</th>
            <th style="width: 2%;">Code</th>
          </tr>
          <template v-for="salesPackageCode in salesPackageCodesList" v-bind:key="salesPackageCode.id">
            <tr>
              <td>{{ salesPackageCode.id }}</td>
              <td>{{ salesPackageCode.code }}</td>
            </tr>
          </template>
        </table>
      </div>
      <div class="col-md-3"></div>
    </div>
  </template>
</template>

<script>
import { SalesPackageService } from '../../../services/admin/SalesPackage/salesPackageService';
import { SalesPackageCodeService } from '../../../services/admin/SalesPackage/salesPackageCodeService';

export default {
  name: 'ManageCodes',
  data() {
    return {
      salesPackageId: null,
      selectedCodesOption: null,
      selectedPackageType: null,
      codesData: {
        codes: null,
        numberOfCodesToGenerate: null
      },
      salesPackageCodesList: []
    };
  },
  mounted() {
    const params = this.$route.params;
    this.salesPackageId = params.id;
    this.initializeView();
  },
  methods: {
    async initializeView() {
      await this.getSalesPackageData();
      await this.getCodesData();
    },
    async getSalesPackageData() {
      const response = await SalesPackageService.getSalesPackageDataById(this.salesPackageId);
      const salesPackage = response.data.salesPackage.items[0];
      this.selectedPackageType = salesPackage.type.type;
      // this.codesData.codes = salesPackage;
    },
    async saveCodes() {
      await SalesPackageCodeService.saveCodes(
        this.salesPackageId,
        this.codesData.codes ? this.codesData.codes.split(',') : null,
        this.codesData.numberOfCodesToGenerate
      );
      window.location.reload();
    },
    async getCodesData() {
      const response = await SalesPackageService.getCodes(this.salesPackageId, 'all', 'json');
      const salesPackageCodes = response.data.salesPackageCodes;
      this.existingCodes = salesPackageCodes.items;

      if (this.selectedPackageType === 'access_code' || this.selectedPackageType === 'discount_code') {
        this.codesData.codes = salesPackageCodes.items[0].code;
      } else if (this.selectedPackageType === 'funpackage' || this.selectedPackageType === 'deal') {
        for (const salesPackageCode of salesPackageCodes.items) {
          this.salesPackageCodesList.push(salesPackageCode);
        }
      }
    },
    setSelectedCodesOption(selectedCodesOption) {
      this.selectedCodesOption = selectedCodesOption;
    }
  }
};
</script>

<style scoped>
#codes-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#codes-table td, #codes-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#codes-table tr:nth-child(even){background-color: #f2f2f2;}

#codes-table tr:hover {background-color: #ddd;}

#codes-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
</style>
